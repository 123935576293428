import React from 'react';
import { Link } from 'react-router-dom';
import Accordion  from '../Accordion/Accordion';


const data = [
  {
      header: " What is a GPS tracking system?",
      body: "A GPS tracking system uses the Global Positioning System to determine and track the precise location of a vehicle or asset in real-time. This data is then transmitted to a central server, where it can be accessed via software to monitor location, speed, and route history.."
  },
  {
      header: " What are fuel sensors, and how do they work? ",
      body: " Fuel sensors measure the level of fuel in a vehicle’s tank. They can be used to track fuel consumption, detect fuel theft, and provide accurate data for managing fuel expenses. These sensors typically use ultrasonic,capacitive, or resistive technologies to measure fuel levels.."
  },
  {
      header: " How accurate is GPS tracking? ",
      body: "GPS tracking systems are typically accurate towithin a few meters. The accuracy can be influenced by factors such as atmospheric conditions, obstructions like tall buildings or trees, and the quality of the GPS receiver."
  },
  {
      header: "Can GPS tracking systems be used internationally?",
      body: "Real-time tracking involves continuously sending location data from the GPS device in the vehicle to a central server. This data is then accessible through software or a mobile app, allowing you to see the current location and movement of your vehicles."
  },
  {
      header: "What types of fuel sensors are available?",
      body: "There are several types of fuel sensors, including ultrasonic sensors, capacitive sensors, and resistive sensors. Each type uses different technology to measure fuel levels and may be more suitable for specific applications or types of vehicles."
  },
  {
      header: "How do fuel sensors help in reducing fuel costs?",
      body: "Fuel sensors provide accurate data on fuel consumption and levels, which can help identify inefficiencies, detect fuel theft, and ensure optimal fuel use. This data enables better fuel management and cost-saving strategies."
  },
  {
      header: "Are fuel sensors compatible with all types of vehicles?",
      body: "Most fuel sensors are designed to be compatible with a wide range of vehicles, including cars, trucks, buses, and heavy machinery. However, its important to check compatibility with your specific vehicle model and type.."
  },

  {
    header: "How difficult is it to install GPS tracking and fuel sensor systems?",
    body: "The installation process varies depending on the system. Some systems are plug-and-play and can be easily installed by the user, while others may require professional installation to ensure proper setup and functionality."
},

{
  header: "What kind of maintenance is required for GPS tracking and fuel sensor systems?",
  body: "Regular maintenance includes checking for firmware updates, ensuring that the devices are properly mounted and connected, and occasionally calibrating sensors for accuracy. Its salso important to monitor the systems for any signs of malfunction or damage."
},
{
  header: "How is the data from GPS and fuel sensors protected?",
  body: "Data from GPS and fuel sensors is typically encrypted and transmitted securely to prevent  unauthorized access. Service providers often have robust security measures in place to protect data privacy and integrity."
}
,
{
  header: "Can I control who has access to my fleet data?",
  body: "Yes, most fleet management systems allow you to set permissions and control who can access different types of data. This ensures that only authorized personnel can view or manage sensitive information."
},

{
  header: "What is the cost of implementing GPS tracking and fuel sensor systems?",
  body: "The cost varies depending on the features, number of vehicles, and service provider. It typically includes the initial purchase of devices, installation fees, and ongoing subscription costs for data services."
}
,
{
  header: "How can I measure the ROI of these systems?",
  body: "ROI can be measured by evaluating improvements in fuel efficiency, reductions in unauthorized vehicle use, better route planning, decreased maintenance costs, and overall improvements in fleet productivity  and management efficiency. Detailed reporting and analytics provided by the software can help quantify these benefits."
},


];


const FAQ = () => {
  return (
    <>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: "url('assets/img/page-header.jpg')" }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>FAQ</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Frequently Asked Questions</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      <section id="faq" className="faq">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2>Frequently Asked Questions</h2>
          </div>

          <div
            className="row justify-content-center aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="col-lg-10">
              <div className="accordion accordion-flush" id="faqlist">
              
              {data.map((item, index)=><Accordion index={index}  header={item.header} body = {item.body}/>)}
              </div>
            </div>
          </div>
        </div>
      </section>


      
    </>
  );
};

export default FAQ;


import React from 'react';
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
    <>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center" style={{ backgroundImage: "url('assets/img/page-header.jpg')" }}>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Blog</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li><Link to="/">Home</Link></li>
              <li>Blog</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      <section id="team" className="team pt-0">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2>Blog</h2>
          </div>

          <div className="row aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            {Array(8).fill(null).map((_, index) => (
              <div key={index} className="col-lg-4 col-md-6 d-flex">
                <div className="member">
                  <img src="assets/img/blog/team-1.jpg" className="img-fluid" alt="Blog post" />
                  <div className="member-content">
                    <h2 className="blog-towbox">
                      <span><i className="fa-solid fa-user"></i>By Alicia Davis</span>
                      <span><i className="fa-solid fa-calendar"></i>January 29, 2024</span>
                    </h2>
                    <h4>Latest Industry Trends</h4>
                    <span>Stay Ahead of the Curve</span>
                    <p>
                      The IT industry is constantly evolving, and staying up-to-date with the latest trends is crucial for maintaining a competitive edge.
                    </p>
                    <Link className="read-more-btn" to="/BlogDetails">
                      Read More<i className="fa-solid fa-arrow-right-long"></i>
                    </Link>
                    {/* Uncomment and use social links if needed */}
                    {/* <div className="social">
                      <a href="#"><i className="bi bi-twitter"></i></a>
                      <a href="#"><i className="bi bi-facebook"></i></a>
                      <a href="#"><i className="bi bi-instagram"></i></a>
                      <a href="#"><i className="bi bi-linkedin"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* End Blog Section */}
    </>
  );
};

export default Blog;

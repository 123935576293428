import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import validator from "validator";
import toast from "react-hot-toast";
import Modal from "../Modal/Modal";
import "./Carrer.css";
import { IoClose } from "react-icons/io5";
import { Link } from 'react-router-dom';

const Carrer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);
  const [send, setSend] = useState("Send Message");
  const [showModal, setShowModal] = useState(false);
  const [title , setTitle] = useState("")

  const [error, setError] = useState({
    name: "",
    email: "",
    number: "",
    file: "",
  });

  const clearData = () => {
    setEmail("");
    setName("");
    setNumber("");
    setFile("");
    setFile(null);
    handleReset();
  };
 
  const clearError = () => {
    setError({
      name: "",
      email: "",
      number: "",
      file: "",
    });
  };

  const [flag, setFlag] = useState(false);
  const [da, setData] = useState([]);
  useEffect(() => {
    const fuun = async () => {
      setFlag(true);
      let { data } = await axios.get(
        "https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/career//get_all_career?company_id=5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641&search&page_no=1&rows_per_page=10"
      );

      data = data.responseObj.result;
      setData(data);
      setFlag(false);
    };
    fuun();
  }, []);

  const handleSubmit = async (event, index) => {
    try {
      event.preventDefault();

      let tempError = {};
      if (!name) {
        // setLoading("")
        setError((prev) => ({ ...prev, name: "Name Is Required" }));
        tempError.name = "Name Is Required";
      } else if (
        !validator.isAlpha(name.replace(/\s+/g, ""), "en-US", { ignore: " " })
      ) {
        // setLoading("")
        setError((prev) => ({
          ...prev,
          name: "Name should only contain letters or spaces",
        }));
        tempError.name = "Name should only contain letters or spaces";
      } else {
        setError((prev) => ({ ...prev, name: "" }));
        tempError.name = "";
      }
      const regExForContact =
        /^\s*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

      if (!number) {
        // setLoading("")
        setError((prev) => ({ ...prev, number: "Number Is Required" }));
        tempError.number = "Number Is Required";
      } else if (!regExForContact.test(number)) {
        // setLoading("")
        setError((prev) => ({ ...prev, number: "Invalid Mobile Number" }));
        tempError.number = "Invalid Mobile Number";
      } else {
        setError((prev) => ({ ...prev, number: "" }));
        tempError.number = "";
      }

      if (!email) {
        // throw new Error('Invalid email address');
        // setLoading("")
        setError((prev) => ({ ...prev, email: "Email Is Required" }));
        tempError.email = "Email Is Required";
      } else if (!validator.isEmail(email)) {
        // throw new Error('Invalid email address');
        // setLoading("")
        setError((prev) => ({ ...prev, email: "Invalid Email" }));
        tempError.email = "Invalid Email";
      } else {
        setError((prev) => ({ ...prev, email: "" }));
        tempError.email = "";
      }

      if (!file) {
        // setLoading("")
        setError((prev) => ({ ...prev, file: "File Is Required" }));
        tempError.file = "File is Required";
      } else {
        setError((prev) => ({ ...prev, file: "" }));
        tempError.file = "";
      }

      if (
        tempError.email ||
        tempError.name ||
        tempError.number ||
        tempError.file
      ) {
        // setLoading("")
        return;
      }

      // setLoading("Loading")
      setSend("Sending...");

      const formData = new FormData();
      formData.append("name", name);
      formData.append("contact", number);
      formData.append("email", email);
      formData.append("resume", file[0]);
      formData.append("company_id","5159D3B3-1E23-4C1B-AFA6-FBC8F1D0F641");
      formData.append("job_title",title)

      // return

      const res = await axios.post(
        "https://service-spotrack-dubphwg5cae3f4da.centralindia-01.azurewebsites.net/api/v1/mail/addCarrierInquiry-satelitekey",
        formData
      );

      let to = res.data.result.result;
      toast.success(to);
      setShowModal(false);
      setSend("Send Message");
      // setLoading("Your message has been sent. Thank you!")

      clearData();
      clearError();
    } catch (error) {
      toast.error("Something Went Wrong")
      clearData();
      setSend("Send Message");
    }
  };

  const handleReset = () => {
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";
    }
    // setError(prev =>{...prev, file: ""})
    setError((prev) => ({ ...prev, name: "" }));
  };

  return (
    <div>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: 'url("assets/img/page-header.jpg")' }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Career</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
            <li>
                <Link to="/">Home</Link>
              </li>
              <li>Career</li>
            </ol>
          </div>
        </nav>
      </div>
      <section id="about" className="about">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          {flag ? (
            <div style={{ textAlign: "center" }}>
              <h4>Loading...</h4>
            </div>
          ) : (
            da.map((job, index) => (
              <div className="row careers-box mt-4" key={index}>
                <div
                  className="col-lg-2 position-relative align-self-start order-lg-last order-first"
                  style={{ textAlign: "right" }}
                >
                  <button
                    type="button"
                    className="apply-now-btn"
                    onClick={() =>{ setShowModal(true); setTitle(job.job_title)}}
                    data-bs-target={`#exampleModal${index}`}
                  >
                    Apply Now
                    <i className="fa-solid fa-angle-right" />
                  </button>
                  {showModal && (
                    <Modal onClose={() => setShowModal(false)}>
                      <div className="copy">
                        <div className="close">
                          <IoClose onClick={() =>{ setShowModal(false); clearData(); clearError();}} />{" "}
                        </div>
                        <div className="row">
                          <div className="col-md-6 form-group">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              id="name"
                              placeholder="Your Name"
                              required
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                                setError((prev) => ({ ...prev, name: "" }));
                              }}
                            />
                            <div style={{ color: "red" }}>{error.name}</div>
                          </div>
                          <div className="col-md-6 form-group mt-3 mt-md-0">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Your Email"
                              required
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setError((prev) => ({ ...prev, email: "" }));
                              }}
                            />
                            <div style={{ color: "red" }}> {error.email}</div>
                          </div>
                        </div>
                        <div className="form-group mt-3">
                          <input
                            type="text"
                            className="form-control"
                            name="subject"
                            id="subject"
                            placeholder="Your Phone number"
                            required
                            onChange={(e) => {
                              setNumber(e.target.value);
                              setError((prev) => ({ ...prev, number: "" }));
                            }}
                            value={number}
                          />
                          <div style={{ color: "red" }}>{error.number}</div>
                        </div>
                        <div className="form-group Attach attach-resume-btn mt-2">
                          <label
                            htmlFor={`formFile${index}`}
                            className="form-label"
                          >
                            Attach Resume (.pdf, .doc, .docx)
                          </label>
                          <input
                            type="file"
                            accept=".pdf, .doc, .docx"
                            className="form-control form-control-lg h-100 a2z"
                            name="document"
                            // value={""}
                            id={`formFile${index}`}
                            onChange={(e) => {
                              setFile(e.target.files);
                              setError((prev) => ({ ...prev, file: "" }));
                            }}
                            required
                            ref={inputFile}
                          />
                          <div style={{ color: "red" }}>{error.file}</div>
                        </div>

                        <div className="text-center mt-3">
                          <button
                            className="sendmessage"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={(e) => handleSubmit(e, index)}
                          >
                            {send}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  )}

                  {/* modal */}
                </div>
                <div className="col-lg-10 content order-last project-manager-text order-lg-first">
                  <h3>
                    <a>{job.job_title}</a>
                  </h3>
                  <h5>{job.company_name}</h5>
                  <p>{job.job_description}</p>
                  <h4>
                    {/* <span><i className="fa-solid fa-money-bill-trend-up" />{job.salary}</span> */}
                    <span>
                      <i className="fa-solid fa-location-dot" />
                      {job.location}
                    </span>
                    <span>
                      <i className="fa-regular fa-clock" />
                      {job.job_type}
                    </span>
                  </h4>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default Carrer;

import React from "react";
import "./index.css";
// import "./css/main.css";
// import Header from "./Components/Header/Header.jsx";
import AppRouter from "./Components/Router/Router.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import Navbar from "./Components/Navbar/Navbar.jsx";

function App() {
  return (
    <div className="App ">
      <div>
        <Navbar />
      </div>
      <div>
        <AppRouter />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;

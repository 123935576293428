import Accordion from 'react-bootstrap/Accordion';
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import './Accordion.css'


function AllCollapseExample(props) {
  return (
    <Accordion className={"rel"}>
      <Accordion.Item  eventKey={props.index}>
      <div className='svg'><HiOutlineQuestionMarkCircle /></div>
        <Accordion.Header >  
        <p className='heading_zz'>
        {props.header}
        </p>
        </Accordion.Header>
        <Accordion.Body className='body_zz'>
          {props.body}
        </Accordion.Body>
      </Accordion.Item>
      
    </Accordion>
  );
}

export default AllCollapseExample;
import React from "react";
import { Link } from 'react-router-dom';


const Services = () => {
  return (
    <>
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{ backgroundImage: "url(assets/img/page-header.jpg)" }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>Services</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
            <li>
                <Link to="/">Home</Link>
              </li>
              <li>Services</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      {/* ======= Featured Services Section ======= */}
      <section id="featured-services" className="featured-services">
        <div className="container">
          <div className="row gy-4">
            <div
              className="col-lg-3 col-md-6 service-item d-flex"
              data-aos="fade-up"
            >
              <div className="icon flex-shrink-0">
                <i className="fa-solid fa-cart-flatbed"></i>
              </div>
              <div>
                <h4 className="title">Real Time Cargo Tracking</h4>
                <p className="description">
                  Real-time cargo tracking service provides continuous,
                  real-time updates on the location, status, and condition of
                  cargo, often using technologies such as GPS, RFID, IoT
                  sensors, and advanced software platforms.
                </p>
                
                <a className="readmore stretched-link">
                  {/* <span>Learn More</span><i className="bi bi-arrow-right"></i> */}
                </a>
              </div>
            </div>
            {/* End Service Item */}

            <div
              className="col-lg-3 col-md-6 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="icon flex-shrink-0">
                <i className="fa-solid fa-truck"></i>
              </div>
              <div>
                <h4 className="title">Safety & Security Services</h4>
                <p className="description">
                  GPS offers real-time location tracking, geofencing, route
                  optimization, and emergency assistance, enhancing asset
                  protection and driver safety. It also monitors driver behavior
                  to promote safer driving practices and uses historical data
                  analysis to identify security risks.
                </p>
                <a className="readmore stretched-link">
                  {/* <span>Learn More</span><i className="bi bi-arrow-right"></i> */}
                </a>
              </div>
            </div>
            {/* End Service Item */}

            <div
              className="col-lg-3 col-md-6 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="icon flex-shrink-0">
                <i className="fa-solid fa-truck-ramp-box"></i>
              </div>
              <div>
                <h4 className="title">Timely deliveries & satisfaction</h4>
                <p className="description">
                  Timely deliveries and satisfaction – We ensure that shipments
                  reach their destinations within the scheduled timeframes and
                  in optimal condition.
                </p>
                <a className="readmore stretched-link">
                  {/* <span>Learn More</span><i className="bi bi-arrow-right"></i> */}
                </a>
              </div>
            </div>
            {/* End Service Item */}

            <div
              className="col-lg-3 col-md-6 service-item d-flex"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="icon flex-shrink-0">
                <i className="fa-solid fa-indian-rupee-sign"></i>
              </div>
              <div>
                <h4 className="title">Optimized Cost Efficient Travel</h4>
                <p className="description">
                  The optimized cost-efficient travel feature is designed to
                  minimize travel expenses and maximize operational efficiency
                  for businesses and individual users. This feature leverages
                  advanced algorithms and real-time data to provide several key
                  benefits.
                </p>
                <a className="readmore stretched-link">
                  {/* <span>Learn More</span><i className="bi bi-arrow-right"></i> */}
                </a>
              </div>
            </div>
            {/* End Service Item */}
          </div>
        </div>
      </section>
      {/* End Featured Services Section */}

      {/* ======= Services Section ======= */}
      <section id="service" className="services pt-0">
        <div className="container aos-init aos-animate" data-aos="fade-up">
          <div className="section-header">
            <h2>Our Services</h2>
          </div>

          <div className="row gy-4">
            <div
              className="col-lg-4 col-md-6 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy">
                <div className="card-img card-img-one">
                  <i className="fa-solid fa-gears"></i>
                </div>
                <h3 className="stretched-link">Adaptable Performance</h3>
                <p>
                  Our product effortlessly adjusts to your needs, boosting
                  efficiency and simplifying your tasks.
                </p>
              </div>
            </div>
            {/* End Card Item */}

            <div
              className="col-lg-4 col-md-6 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy">
                <div className="card-img card-img-one">
                  <i className="fa-solid fa-screwdriver-wrench"></i>
                </div>
                <h3 className="stretched-link">Built to Last</h3>
                <p>
                  Experience unmatched durability that goes above and beyond
                  with lasting investment.
                </p>
              </div>
            </div>
            {/* End Card Item */}

            <div
              className="col-lg-4 col-md-6 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy">
                <div className="card-img card-img-one">
                  <i className="fa-regular fa-thumbs-up"></i>
                </div>
                <h3 className="stretched-link">Great User Experience</h3>
                <p>
                  Integrate our product into your routine with an intuitive and
                  easy-to-use interface.
                </p>
              </div>
            </div>
            {/* End Card Item */}

            <div
              className="col-lg-4 col-md-6 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy">
                <div className="card-img card-img-one">
                  <i className="fa-regular fa-lightbulb"></i>
                </div>
                <h3 className="stretched-link">Innovative Functionality</h3>
                <p>
                  Stay ahead with features that set new standards, addressing
                  your evolving needs better than the rest.
                </p>
              </div>
            </div>
            {/* End Card Item */}

            <div
              className="col-lg-4 col-md-6 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy">
                <div className="card-img card-img-one">
                  <i className="fa-solid fa-headset"></i>
                </div>
                <h3 className="stretched-link">Reliable Support</h3>
                <p>
                  Count on our responsive customer support, offering assistance
                  that goes beyond the purchase.
                </p>
              </div>
            </div>
            {/* End Card Item */}

            <div
              className="col-lg-4 col-md-6 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card card-copy">
                <div className="card-img card-img-one">
                  <i className="fa-brands fa-searchengin"></i>
                </div>
                <h3 className="stretched-link">Precision in Every Detail</h3>
                <p>
                  Enjoy a meticulously crafted product where small touches make
                  a significant impact on your overall experience.
                </p>
              </div>
            </div>
            {/* End Card Item */}
          </div>
        </div>
      </section>
      {/* End Services Section */}
    </>
  );
};

export default Services;

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="row gy-4">
          {/* Footer Info */}
          <div className="col-lg-5 col-md-12 footer-info">
            <a className="logo d-flex align-items-center">
              <span>
                <img src="assets/img/SUUGAM-LOGO1.png" alt="Logo" />
              </span>
            </a>
            <p className="pt-2 m-0">
              <b>Suugam Techknow World Private Limited</b>
            </p>
            <p className="pt-2 m-0">
              At SPOTRACK, our customers are valued to be first and sticking to
              the promise of providing excellence in services; we co-operate
              with our customers to make the “Future better together.”
            </p>
            <div className="social-links d-flex mt-3">
              <a
                href="https://www.facebook.com/suugam.spotrack"
                className="facebook"
              >
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a
                href="https://www.youtube.com/@Suugam.Spotrack"
                className="youtube"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a
                href="https://www.instagram.com/spotrack.in_/"
                className="instagram"
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/suugamspotrack/"
                className="linkedin"
              >
                <i className="fa-brands fa-linkedin"></i>
              </a>
              <a
                href="https://x.com/i/flow/login?redirect_after_login=%2FSuugam_Spotrack"
                className="twitter"
              >
                <i className="fa-brands fa-x-twitter"></i>
              </a>
            </div>
          </div>

          {/* Useful Links */}
          <div className="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <Link to="/Home" onClick={handleLinkClick}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/AboutUs" onClick={handleLinkClick}>
                  About us
                </Link>
              </li>
              <li>
                <Link to="/services" onClick={handleLinkClick}>
                  Services
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={handleLinkClick}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Our Services */}
          <div className="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              {/* <li>
                <Link to="/Blog" onClick={handleLinkClick}>
                  Blog
                </Link>
              </li> */}
              <li>
                <Link to="/Carrer" onClick={handleLinkClick}>
                  Career
                </Link>
              </li>
              <li>
                <Link to="/faq" onClick={handleLinkClick}>
                  FAQs
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" onClick={handleLinkClick}>
                  Privacy-Policy
                </Link>
              </li>
              <li>
                <Link to="/Terms" onClick={handleLinkClick}>
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Us */}
          <div className="col-lg-3 col-md-12 footer-contact text-md-start">
            <h4>Contact Us</h4>
            <p>
              <strong>Phone:</strong> +91-97846-32155
              <br />
              <strong>Email:</strong> info@suugam.com
              <br />
              <strong>Corporate Office:</strong> Yogi Tower, Mahalaxmi Nagar
              Road, D-Block, Malviya Nagar, Jaipur - 302017, Rajasthan
            </p>
            <div
              style={{ justifyContent: "center" }}
              className="social-links combo-logo-footer d-flex mt-4"
            >
              <a className="combo-logo">
                <img src="assets/img/mms-logo.png" alt="MMS Logo" />
              </a>
              <a className="combo-logo">
                <img src="assets/img/egac-logo.png" alt="EGAC Logo" />
              </a>
              <a className="combo-logo">
                <img src="assets/img/iaf-logo.png" alt="IAF Logo" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright copyright-one mt-4 zzzzz ">
        <div className="container">
          <div className="row align-items-center IT-A">
            <div className="col-lg-8 col-6 IT-B">
              <div className="copyright-text">
                &copy;  Copyright{" "}
                <strong>
                  <span>Suugam.com {""}</span>
                </strong>{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "0px",
                }}
              >
                A Site Designed and maintained by{" "}
                <a
                  style={{ color: "white", marginLeft: "5px" }}
                  href="https://satellitekeyit.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>SatelliteKey IT Solutions</strong>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-6 Icons_a">
              <div className="social-links app-store-footer app-store-links icons_b">
                <a
                  href="https://apps.apple.com/in/app/spotrack/id6468949583"
                  className="combo-logo-one"
                >
                  <img src="assets/img/app-store.png" alt="App Store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.spotrack.tracker&hl=en_IN"
                  className="combo-logo-one"
                >
                  <img src="assets/img/google-play.png" alt="Google Play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="naaaa">
        <div className=" Icons_a">
          <a
            href="https://apps.apple.com/in/app/spotrack/id6468949583"
            className="combo-logo-one"
          >
            <img
              className="aaa"
              src="assets/img/app-store.png"
              alt="App Store"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.spotrack.tracker&hl=en_IN"
            className="combo-logo-one"
          >
            <img
              className="aaa"
              src="assets/img/google-play.png"
              alt="Google Play"
            />
          </a>
        </div>

        <div className="bbb">
          A Site Designed and maintained by{" "}
          <a
            style={{ color: "white", marginLeft: "5px" }}
            href="https://satellitekeyit.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <br />{" "}
            <strong
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              SatelliteKey IT Solutions
            </strong>
          </a>
        </div>

        {/* copyright */}

        <div className="copyright-text">
          &copy; Copyright{" "}
          <strong style={{ marginLeft: "5px" }}>
            <span>Spotrack.in {""}</span>
          </strong>{" "}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

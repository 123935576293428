import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../../Components/Home/Home";
import Carrer from "../../Components/Carrer/Carrer";
import AboutUs from "../../Components/About/About";
import Blog from "../../Components/Blog/Blog";
import Product from "../../Components/Product/Product";
import FAQ from "../Faq/Faq";
import BlogDetails from "../../Components/Blogdetails/Blogdetails";
import Enquiry from "../../Components/Enquiry/Enquiry";
import News from "../../Components/News/News";
import PrivacyPolicy from "../../Components/Privacy-policy/Privacy_policy";
import Services from "../../Components/Services/Services";
import TermsAndConditions from "../../Components/TermsAndConditions/TermsAndConditions";
import Contact from "../../Components/Contact/Contactus";

const AppRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="Home" element={<Home />} />

      <Route exact path="/" element={<Carrer />} />
      <Route path="Carrer" element={<Carrer />} />

      <Route exact path="/" element={<AboutUs />} />
      <Route path="AboutUs" element={<AboutUs />} />

      <Route exact path="/" element={<Blog />} />
      <Route path="Blog" element={<Blog />} />

      <Route exact path="/" element={<Product />} />
      <Route path="Product" element={<Product />} />

      <Route exact path="/" element={<FAQ />} />
      <Route path="FAQ" element={<FAQ />} />

      <Route exact path="/" element={<BlogDetails />} />
      <Route path="BlogDetails" element={<BlogDetails />} />

      <Route exact path="/" element={<Enquiry />} />
      <Route path="Enquiry" element={<Enquiry />} />

      <Route exact path="/" element={<News />} />
      <Route path="News" element={<News />} />

      <Route exact path="/" element={<PrivacyPolicy />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />

      <Route exact path="/" element={<Services />} />
      <Route path="Services" element={<Services />} />

      <Route exact path="/" element={<Contact />} />
      <Route path="Contact" element={<Contact />} />

      <Route exact path="/" element={<TermsAndConditions />} />
      <Route path="Terms" element={<TermsAndConditions />} />

    </Routes>
  );
};

export default AppRouter;

import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { IoMenu } from "react-icons/io5";
import { IoIosClose } from "react-icons/io";

const Navbar = () => {
  const dropdownRef = useRef();
  const [showNavbar, setShowNavbar] = useState(false);

  const handleClick = (event) => {
    if (dropdownRef && !dropdownRef.current?.contains(event.target))
      setShowNavbar(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  const handleShowNavbar = () => {
    setShowNavbar((prev) => !prev);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <nav ref={dropdownRef} className="navbar-a">
      <div className="container-a">
        <div className="logo-a">
          <NavLink to="/Home" onClick={()=> window.scrollTo({ top: 0, behavior: "smooth" })}>
            <img
              className="spotrackLogo-a"
              src="assets/img/SUUGAM-LOGO1.png"
              alt="Logo"
            />
          </NavLink>
        </div>

        <div
          className="menu-icon-a"
          onClick={() => setShowNavbar((prev) => !prev)}
        >
          <IoMenu />
        </div>

        <div className={`nav-elements-a  ${showNavbar && "active-a"}`}>
          <div className="close-a">
            <IoIosClose onClick={() => setShowNavbar((prev) => !prev)} />
          </div>

          <ul>
            <NavLink to="/Home">
              <li onClick={handleShowNavbar}>Home</li>
            </NavLink>

            <NavLink to="/Product">
              <li onClick={handleShowNavbar}>Product</li>
            </NavLink>

            <NavLink to="/AboutUs">
              <li onClick={handleShowNavbar}>About</li>
            </NavLink>

            <NavLink to="/services">
              <li onClick={handleShowNavbar}>Services</li>
            </NavLink>

            <NavLink to="/faq">
              <li onClick={handleShowNavbar}>FAQ</li>
            </NavLink>

            <NavLink to="/contact">
              <li onClick={handleShowNavbar}>Contact</li>
            </NavLink>

            <NavLink to="https://beta-console.spotrack.in/">
              <li className="li-last-a" onClick={handleShowNavbar}>
                <button className="btn-a">Login</button>
              </li>
            </NavLink>

            <NavLink to="/Enquiry">
              <li className="li-last-a" onClick={handleShowNavbar}>
                <button className="btn-a">Enquiry</button>
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from 'react';
// import './News.css'; // Import CSS file if you have specific styles

const News = () => {
  return (
    <>
      <div className="breadcrumbs">
        <div 
          className="page-header d-flex align-items-center" 
          style={{ backgroundImage: 'url(assets/img/page-header.jpg)' }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2>News</h2>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div className="container">
            <ol>
              <li><a href="index.html">Home</a></li>
              <li>News</li>
            </ol>
          </div>
        </nav>
      </div>
      {/* End Breadcrumbs */}

      {/* ======= News Section ======= */}
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="row gy-4 align-items-center">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img 
                src="assets/img/news-photo.jpg" 
                className="img-fluid about-photo" 
                alt="News"
              />
            </div>
            <div className="col-lg-6 content order-last order-lg-end">
              <p className="news-text">
                <span>
                  <img src="assets/img/aaj-tak-logo.png" alt="Aaj Tak Logo" /> Aaj Tak |
                </span> 
                12 minutes ago
              </p>
              <h3>Spotrack Launches New Cybersecurity Service</h3>
              <p>
                We are excited to announce the launch of our new cybersecurity service designed to protect businesses from the growing threat of cyber attacks. Our comprehensive solution includes advanced threat detection, incident response, and ongoing monitoring to ensure your data remains secure.
              </p>
              <p className="news-text">
                <span>News |</span> 4 min read
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End News Section */}

      {/* ======= Latest News Section ======= */}
      <section id="team" className="team pt-0">
        <div className="container" data-aos="fade-up">
          <div className="section-header">
            <h2>Latest News</h2>
          </div>
          <div className="row aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            {Array(6).fill().map((_, index) => (
              <div key={index} className="col-lg-4 col-md-6 d-flex">
                <div className="member member-news">
                  <img 
                    src="assets/img/news-photo1.jpg" 
                    className="img-fluid" 
                    alt={`News ${index + 1}`} 
                  />
                  <div className="member-content member-content-news">
                    <p className="news-text news-text12">
                      <span>
                        <img src="assets/img/aaj-tak-logo.png" alt="Aaj Tak Logo" /> Aaj Tak / 12 minutes ago
                      </span>
                    </p>
                    <h4>Spotrack Reports Record Growth in 2024</h4>
                    <p>
                      We are delighted to report record growth in 2024, with significant increases in revenue and client base. This success is a testament to the hard work and dedication of our team, as well as the trust and support of our clients.
                    </p>
                    <p className="news-text news-text11 news-text22">
                      <span>News / 12 minutes ago</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* End Latest News Section */}
    </>
  );
};

export default News;
